import React, { useState, useCallback, useEffect } from "react";
import "@fontsource/roboto/400.css";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import styled from 'styled-components';

const Style = styled.div`
  .link-styles {
    cursor: pointer;
  }

`;


function Register() {


  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate()
  const location = useLocation();

  const handleLoginClick = useCallback(() => {
    navigate("/login");
  }, [])

  

  const generatePassword = () => {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      password += charset.charAt(Math.floor(Math.random() * n));
    }
    return password;
  };

  const handleGeneratePassword = () => {
    setPassword(generatePassword());
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("username", username);
    formData.append("email", email);
    formData.append("password", password);
    const requestMetadata = {
      method: 'POST',
      headers: {
          'Authorization': ''
      },
      body: formData
    };    

    //const formDataValues = [...formData]
    fetch("./register.php", requestMetadata)
    .then(response => response.json())
    .then((details) => {
        //console.log('details '+ JSON.stringify(details.message))
        if(details){
            setApiResponse(details.message);
        }
    })
    .catch((error) => {
        //console.error(error);

    });
  };

  return (
   <Style>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "5%",
        width: "100%"
      }}
    >
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit}>
          <Typography
            style={{ marginBottom: "20px" }}
            variant="h2"
            component="h2"
          >
            Sign up
          </Typography>
          {apiResponse && (
            <Alert
              style={{ marginBottom: "20px" }}
              severity={apiResponse.includes("success") ? "success" : "error"}
            >
              {apiResponse}
              {
              apiResponse.includes("success") ? 
              <Link className={"link-styles"}  onClick={()=> {
                handleLoginClick()
               }}
              > Log in</Link>
              : null
              }
            </Alert>
          )}
          <TextField
            type="text"
            value={username}
            onChange={handleUsernameChange}
            variant="filled"
            id="filled-basic"
            label="Username"
            fullWidth
            style={{ marginBottom: "20px", backgroundColor: "rgba(204, 204, 204, 0.6)" }}
          />
          <TextField
            type="email"
            value={email}
            onChange={handleEmailChange}
            variant="filled"
            id="filled-basic"
            label="Email address"
            fullWidth
            style={{ marginBottom: "20px", backgroundColor: "rgba(204, 204, 204, 0.6)" }}
          />
          <div>
            <TextField
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              variant="filled"
              id="filled-basic"
              label="Password"
              fullWidth
              style={{ marginBottom: "10px", backgroundColor: "#ccc" }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
            <Button
              size="small"
              style={{ marginBottom: "25px" }}
              onClick={handleGeneratePassword}
            >
              Generate password
            </Button>
          </div>
          <Button variant="contained" type="submit">
            Sign up
          </Button>
          <Typography
            variant="body1"
            gutterBottom
            style={{ marginTop: "20px", cursor: "pointer" }}
          >
            Do You have an account?
            <Link onClick={()=> {
                  handleLoginClick()
                 }}
              > Log in</Link>
          </Typography>
        </form>
      </Container>
    </Box>
    </Style>
  );
}

export default Register;