import React, { useState, useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";

function Login({showLogin, showRegister, onShowLoginChange, onShowRegisterChange}) {


  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate()
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/login') {
        navigate('/login')
      }
  },[])


  const handleRegisterClick = useCallback(() => {
    navigate("/register")
  }, [onShowLoginChange, onShowRegisterChange, showLogin, showRegister])


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    const requestMetadata = {
      method: 'POST',
      headers: {
          'Authorization': ''
      },
      body: formData
    };    

    fetch("./login.php", requestMetadata)
    .then(response => response.json())
    .then((details) => {
       if (details.status === "success") {
          sessionStorage.setItem("loggedIn", true);
          sessionStorage.setItem(
            "name",
            details.data.name
          );
          sessionStorage.setItem(
            "uid",
            details.data.uid
          );

          window.location.href = "/";
        } else {
          setError(details.message);
        }
      })
      .catch((error) => {
        //console.error(error);
      });
  };

  return (
   
      <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "5%",
      }}
    >
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit}>
          <Typography
            style={{ marginBottom: "20px" }}
            variant="h2"
            component="h2"
          >
            Log in
          </Typography>
          {error && (
            <Alert style={{ marginBottom: "20px" }} severity="error">
              {error}
            </Alert>
          )}
          <TextField
            type="email"
            value={email}
            onChange={handleEmailChange}
            variant="filled"
            id="email"
            label="Email address"
            fullWidth
            style={{ marginBottom: "20px", backgroundColor: "rgba(204, 204, 204, 0.6)" }} />
          <TextField
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            variant="filled"
            id="password"
            label="Password"
            fullWidth
            style={{ marginBottom: "20px", backgroundColor: "rgba(204, 204, 204, 0.6)" }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleShowPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }} />
          <Button variant="contained" type="submit">
            Login
          </Button>
          <Typography
            variant="body1"
            gutterBottom
            style={{ marginTop: "20px", cursor: "pointer" }}
          >
            Don't have an account? 
              <Link onClick={()=> {
                  handleRegisterClick()
                 }}
              > Sign up</Link>

          </Typography>
        </form>
      </Container>
    </Box>
    


  );
}

export default Login;

