import React from 'react';
import { Link } from 'react-router-dom';

export default function Description({ generatedContent }) {


    return (
        <div className="dashboard">
          <header>
            <h1>Dashboard</h1>
            <Link to="/">Back to Home</Link>
          </header>
          <main>
            <section className="generated-content">
              {generatedContent.productName ? (
                <div>
                  <h2>{generatedContent.productName}</h2>
                </div>
              ) : (
                <p>No content generated yet. Go back to the home page to generate content.</p>
              )}
            </section>
        
            <section className="generated-content">
              {generatedContent.categorization ? (
                <div>
                  <h3>Google taxonomy category</h3>  
                     <div>ID : {generatedContent.categorization.id}</div>
                     <div>{generatedContent.categorization.taxonomy}</div>
                </div>
              ) : (
                <p>Categorization failed.</p>
              )}
            </section>
        
            <section className="generated-content">
              <h2>Images</h2>
              {generatedContent.images ? (
                <div>
                  <div dangerouslySetInnerHTML={generatedContent.images}/>
                </div>
              ) : (
                <p>Unable to suggest related image. Sorry for the inconvenience.</p>
              )}
            </section>

            <section className="generated-content">
              <h2>Video</h2>
              {generatedContent.video ? (
                <div>
                  <div dangerouslySetInnerHTML={generatedContent.video}/>
                </div>
              ) : (
                <p>Unable to suggest related video. Sorry for the inconvenience.</p>
              )}
            </section>

            <section className="generated-content">
              <h2>Meta descriptions</h2>
              {generatedContent.metaDescription ? (
                <div>
                  <div dangerouslySetInnerHTML={generatedContent.metaDescription}/>
                </div>
              ) : (
                <p>Meta description failed. Sorry for the inconvenience.</p>
              )}
            </section>

            <section className="generated-content">
              <h2>Short description</h2>
              {generatedContent.shortDescription ? (
                <div>
                  <div dangerouslySetInnerHTML={generatedContent.shortDescription}/>
                </div>
              ) : (
                <p>Short description failed. Sorry for the inconvenience.</p>
              )}
            </section>

            <section className="generated-content">
              <h2>Full description</h2>
              {generatedContent.fullDescription ? (
                <div>
                  <div dangerouslySetInnerHTML={generatedContent.fullDescription}/>
                </div>
              ) : (
                <p>Full description failed. Sorry for the inconvenience.</p>
              )}
            </section>
          </main>
        </div>
      );

}