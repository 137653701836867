import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Contact() {
  const [message, setMessage] = useState('');
  const [confirmation, setConfirmation] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    

    const url = './contact.php'
    const contactUserId = sessionStorage.getItem('uid');
    const contactName = sessionStorage.getItem('name');
    
 
    if(message.trim() !== ''){
 
       const body = {
           userId: contactUserId,
           userName: contactName,
           msg: message,
       };
 
       //console.log('body '+JSON.stringify(body))
     
       const requestMetadata = {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json',
               'Authorization': ''
           },
           body: JSON.stringify(body)
       };
 
       fetch(url, requestMetadata)
       .then(async response => {
          const data = await response.json();
          //console.log('data '+JSON.stringify(data))
          // check for error response
          if (!response.ok) {
              // get error message from body or default to response statusText
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
          }
          else{
              if(data === 1){
                setConfirmation('Your message has been received. We usually reply within 24 hours.');
                setMessage(''); // Clear the textarea
                
              }           
    
          }
      })
      .catch(error => {
          //this.setState({ errorMessage: error.toString() });
          console.error('There was an error!', error);
      });      
 
 
     }
 



  };

  return (
    <div className="dashboard">
      <header>
        <h1>Contact us</h1>
        <Link to="/">Back to Home</Link>
      </header>
     <main>
     <section className="contact-block">

    <div className="contact-container">
      <form className="contact-form" onSubmit={handleSubmit}>
        <textarea 
          id="contact-textarea"
          placeholder="Enter your message here..." 
          value={message} 
          onChange={(e) => setMessage(e.target.value)} 
          required 
          rows="8" 
        />
        <button type="submit">Send Message</button>
      </form>
      {confirmation && <div className="confirmation-message">{confirmation}</div>} {/* Display confirmation message */}
    </div>
    </section>
  </main>
</div>    

  );
}

export default Contact;