export async function requestProductName (uid, requestIdent, requestTitle, selectedLanguage) {
    const formData = new FormData();
    formData.append("uid", uid)
    formData.append("ident", requestIdent)
    formData.append("title", requestTitle)
    formData.append("language", selectedLanguage)
    const response = await fetch('./requests/add-product-name.php', {
      method: 'POST',
      body: formData
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data
  }
