import React from 'react';
import { Link } from 'react-router-dom';

export default function Pricing() {

    return (
        <div className="dashboard">
          <header>
            <h1>Pricing</h1>
            <Link to="/">Back to Home</Link>
          </header>
          <main>
            <section className="pricing-block">

                 <div className="coming-soon">Enjoy free ride during beta test period.</div>

            </section>
          </main>
        </div>    


       )

}