export async function requestVideo (requestIdent, requestTitle) {
    const formData = new FormData();
    formData.append("ident", requestIdent)
    formData.append("title", requestTitle)
    const response = await fetch('./youtube-video-search/request.php', {
      method: 'POST',
      //headers: {
      //  'Content-type': 'text/html'
      //},
      body: formData
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.text();
    //console.log('Generated content:', data);
    if(data == '2'){
      return false;
    }
    else{
      return {__html: data}
    }
  }