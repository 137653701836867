import React from 'react';
import { Link } from 'react-router-dom';

export default function Privacy() {

    return (
        <div className="dashboard">
          <header>
            <h1>Privacy Policy</h1>
            <Link to="/">Back to Home</Link>
          </header>
          <main>
            <section className="privacy-block">
                <p>
                    At WTFF.com, we value your privacy and are committed to protecting your personal information. When you interact with our AI services, we may collect certain data to enhance your experience and improve our offerings.
                </p>
                <p>
                    The information we gather may include, but is not limited to, your input queries, preferences, and usage patterns. This data helps us understand how our AI services are utilized and allows us to make necessary adjustments to better serve our users.
                </p>
                <p>
                    We want to assure you that any data collected is handled with the utmost care. While we may share aggregated and anonymized data with third parties for analytical purposes, we do not sell or disclose your personal information to unauthorized entities.
                </p>
                <p>
                    Our AI systems are designed to respect user privacy. However, please be aware that no system can be completely secure. We encourage you to exercise caution when sharing sensitive information and to be mindful of the data you provide during your interactions.
                </p>
                <p>
                    By using our services, you acknowledge that you have read and understood this privacy policy. We may update this policy from time to time, and we encourage you to review it periodically to stay informed about how we are protecting your information.
                </p>
                <p>
                    If you have any questions or concerns regarding our privacy practices, please feel free to contact us.
                </p>
    
            </section>
          </main>
        </div>    


       )

}