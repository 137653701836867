import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router,  Routes,  Route} from "react-router-dom"
import './GlobalStyle.css';
import Home from './Home';
import Dashboard from './Dashboard';
import Privacy from './Privacy';
import Register from './Register';
import Login from './Login'
import Affiliate from './Affiliate'
import Pricing from './Pricing'
import Contact from './Contact'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>  
        <Routes>
          <Route index element={<Home/>} />
          <Route path="/dashboard" 
                 element={<Home renderDashboard={(generatedContent) => <Dashboard generatedContent={generatedContent} />} />}
          />
          <Route path="/privacy" element={<Privacy/>} />
          <Route path="/register" element={<Register/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/pricing" element={<Pricing/>} />
          <Route path="/affiliate" element={<Affiliate/>} />
          <Route path="/contact" element={<Contact/>} />
        </Routes>
    </Router> 
  </React.StrictMode>

);