export const languages = [
    {"name":"Afrikaans","key":"af"}, 
    {"name":"Albanian","key":"sq"}, 
    {"name":"Amharic","key":"am"}, 
    {"name":"Arabic","key":"ar"}, 
    {"name":"Armenian","key":"hy"}, 
    {"name":"Assamese","key":"as"}, 
    {"name":"Aymara","key":"ay"}, 
    {"name":"Azerbaijani","key":"az"}, 
    {"name":"Bambara","key":"bm"}, 
    {"name":"Basque","key":"eu"}, 
    {"name":"Belarusian","key":"be"}, 
    {"name":"Bengali","key":"bn"}, 
    {"name":"Bhojpuri","key":"bho"}, 
    {"name":"Bosnian","key":"bs"}, 
    {"name":"Bulgarian","key":"bg"}, 
    {"name":"Catalan","key":"ca"}, 
    {"name":"Cebuano","key":"ceb"}, 
    {"name":"Chinese (Simplified)","key":"zh-CN"}, 
    {"name":"Chinese (Traditional)","key":"zh-TW"}, 
    {"name":"Corsican","key":"co"}, 
    {"name":"Croatian","key":"hr"}, 
    {"name":"Czech","key":"cs"}, 
    {"name":"Danish","key":"da"}, 
    {"name":"Dhivehi","key":"dv"}, 
    {"name":"Dogri","key":"doi"}, 
    {"name":"Dutch","key":"nl"}, 
    {"name":"English","key":"en"}, 
    {"name":"Esperanto","key":"eo"}, 
    {"name":"Estonian","key":"et"}, 
    {"name":"Ewe","key":"ee"}, 
    {"name":"Filipino (Tagalog)","key":"fil"}, 
    {"name":"Finnish","key":"fi"}, 
    {"name":"French","key":"fr"}, 
    {"name":"Frisian","key":"fy"}, 
    {"name":"Galician","key":"gl"}, 
    {"name":"Georgian","key":"ka"}, 
    {"name":"German","key":"de"}, 
    {"name":"Greek","key":"el"}, 
    {"name":"Guarani","key":"gn"}, 
    {"name":"Gujarati","key":"gu"}, 
    {"name":"Haitian Creole","key":"ht"}, 
    {"name":"Hausa","key":"ha"}, 
    {"name":"Hawaiian","key":"haw"}, 
    {"name":"Hebrew","key":"he"}, 
    {"name":"Hindi","key":"hi"}, 
    {"name":"Hmong","key":"hmn"}, 
    {"name":"Hungarian","key":"hu"}, 
    {"name":"Icelandic","key":"is"}, 
    {"name":"Igbo","key":"ig"}, 
    {"name":"Ilocano","key":"ilo"}, 
    {"name":"Indonesian","key":"id"}, 
    {"name":"Irish","key":"ga"}, 
    {"name":"Italian","key":"it"}, 
    {"name":"Japanese","key":"ja"}, 
    {"name":"Javanese","key":"jw"}, 
    {"name":"Kannada","key":"kn"}, 
    {"name":"Kazakh","key":"kk"}, 
    {"name":"Khmer","key":"km"}, 
    {"name":"Kinyarwanda","key":"rw"}, 
    {"name":"Konkani","key":"gom"}, 
    {"name":"Korean","key":"ko"}, 
    {"name":"Krio","key":"kri"}, 
    {"name":"Kurdish","key":"ku"}, 
    {"name":"Kurdish (Sorani)","key":"ckb"}, 
    {"name":"Kyrgyz","key":"ky"}, 
    {"name":"Lao","key":"lo"}, 
    {"name":"Latin","key":"la"}, 
    {"name":"Latvian","key":"lv"}, 
    {"name":"Lingala","key":"ln"}, 
    {"name":"Lithuanian","key":"lt"}, 
    {"name":"Luganda","key":"lg"}, 
    {"name":"Luxembourgish","key":"lb"}, 
    {"name":"Macedonian","key":"mk"}, 
    {"name":"Maithili","key":"mai"}, 
    {"name":"Malagasy","key":"mg"}, 
    {"name":"Malay","key":"ms"}, 
    {"name":"Malayalam","key":"ml"}, 
    {"name":"Maltese","key":"mt"}, 
    {"name":"Maori","key":"mi"}, 
    {"name":"Marathi","key":"mr"}, 
    {"name":"Meiteilon (Manipuri)","key":"mni-Mtei"}, 
    {"name":"Mizo","key":"lus"}, 
    {"name":"Mongolian","key":"mn"}, 
    {"name":"Myanmar (Burmese)","key":"my"}, 
    {"name":"Nepali","key":"ne"}, 
    {"name":"Norwegian","key":"no"}, 
    {"name":"Nyanja (Chichewa)","key":"ny"}, 
    {"name":"Odia (Oriya)","key":"or"}, 
    {"name":"Oromo","key":"om"}, 
    {"name":"Pashto","key":"ps"}, 
    {"name":"Persian","key":"fa"}, 
    {"name":"Polish","key":"pl"}, 
    {"name":"Portuguese","key":"pt"}, 
    {"name":"Punjabi","key":"pa"}, 
    {"name":"Quechua","key":"qu"}, 
    {"name":"Romanian","key":"ro"}, 
    {"name":"Russian","key":"ru"}, 
    {"name":"Samoan","key":"sm"}, 
    {"name":"Sanskrit","key":"sa"}, 
    {"name":"Scots Gaelic","key":"gd"}, 
    {"name":"Sepedi","key":"nso"}, 
    {"name":"Serbian","key":"sr"}, 
    {"name":"Sesotho","key":"st"}, 
    {"name":"Shona","key":"sn"}, 
    {"name":"Sindhi","key":"sd"}, 
    {"name":"Sinhala (Sinhalese)","key":"si"}, 
    {"name":"Slovak","key":"sk"}, 
    {"name":"Slovenian","key":"sl"}, 
    {"name":"Somali","key":"so"}, 
    {"name":"Spanish","key":"es"}, 
    {"name":"Sundanese","key":"su"}, 
    {"name":"Swahili","key":"sw"}, 
    {"name":"Swedish","key":"sv"}, 
    {"name":"Tagalog (Filipino)","key":"tl"}, 
    {"name":"Tajik","key":"tg"}, 
    {"name":"Tamil","key":"ta"}, 
    {"name":"Tatar","key":"tt"}, 
    {"name":"Telugu","key":"te"}, 
    {"name":"Thai","key":"th"}, 
    {"name":"Tigrinya","key":"ti"}, 
    {"name":"Tsonga","key":"ts"}, 
    {"name":"Turkish","key":"tr"}, 
    {"name":"Turkmen","key":"tk"}, 
    {"name":"Twi (Akan)","key":"ak"}, 
    {"name":"Ukrainian","key":"uk"}, 
    {"name":"Urdu","key":"ur"}, 
    {"name":"Uyghur","key":"ug"}, 
    {"name":"Uzbek","key":"uz"}, 
    {"name":"Vietnamese","key":"vi"}, 
    {"name":"Welsh","key":"cy"}, 
    {"name":"Xhosa","key":"xh"}, 
    {"name":"Yiddish","key":"yi"}, 
    {"name":"Yoruba","key":"yo"}, 
    {"name":"Zulu","key":"zu"}, 
    ];

       
