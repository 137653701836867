import ReactGA from 'react-ga4';
import React, {useState, useEffect, useLayoutEffect} from 'react';
import { languages } from './libs/languages';
import { useNavigate, useLocation, Link } from "react-router-dom";
import {requestProductName} from './libs/requestProductName'
import {requestFullDescription} from './libs/requestFullDescription'
import {requestShortDescription} from './libs/requestShortDescription'
import {requestMetaDescription} from './libs/requestMetaDescription'
import {requestCategorization} from './libs/requestCategorization'
import {requestImages} from './libs/requestImages'
import {requestVideo} from './libs/requestVideo'
//import $ from "jquery";

export default function Home({renderDashboard}) {
  ReactGA.initialize('G-G6P12SH7XL');

  const [showAlert, setShowAlert] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    return localStorage.getItem('selectedLanguage') || 'English';
  }); 
  const [generatedContent, setGeneratedContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [progress, setProgress] = useState(0); 
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showLogin, setShowLogin] = useState(false)
  const [username, setUsername] = useState(null)
  const [uid, setUid] = useState(null)
  const navigate = useNavigate(); 
  const location = useLocation();

  useLayoutEffect(() => {
    if(sessionStorage.loggedIn === undefined){
      setShowLogin(true)
    }
  },[])


  useEffect(() => {
    setUid(sessionStorage.getItem('uid'))
    setUsername(sessionStorage.getItem('name'))
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    document.body.style.margin = '0';
    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.style.margin = '';
    };
  }, [uid, username]);

  
  useEffect(() => {
    localStorage.setItem('selectedLanguage', selectedLanguage);
  }, [selectedLanguage]);  

  useEffect(() => {
    const handlePopState = () => {
      // Clear generated content when navigating back
      setGeneratedContent(null);
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);


  const generateIdent = () => {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let ident = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      ident += charset.charAt(Math.floor(Math.random() * n));
    }
    return ident;
  };




  async function submitRequest (event){
    event.preventDefault();
    const requestTitle = event.target.elements.productName.value.trim();
    const requestIdent = generateIdent()
   
    if (!requestTitle) {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
      return;
    }

    setShowAlert(false);  
    setIsLoading(true);
    setProgress(0);
  

    // Simulate progress
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 50) {
          return 0; // Reset progress to 0 after reaching 100%
        }
        return Math.min(oldProgress + 5, 50); // Increment progress
      });
    }, 100); // Update every 100ms

    // Simulate API call
    //await new Promise(resolve => setTimeout(resolve, 5000)); 

    try {
      if(!showLogin){
        const addProductName = await requestProductName (uid, requestIdent, requestTitle, selectedLanguage)
        if(addProductName == 1){
          const fullDescription = await requestFullDescription(uid, requestIdent, requestTitle, selectedLanguage)
          const shortDescription = await requestShortDescription(uid, requestIdent, requestTitle, selectedLanguage)
          const metaDescription = await requestMetaDescription (uid, requestIdent, requestTitle, selectedLanguage)
          const categorization = await requestCategorization (uid, requestIdent, requestTitle, selectedLanguage)
          const images = await requestImages(uid, requestIdent, requestTitle)
          const video = await requestVideo(uid, requestIdent, requestTitle)

          
          //console.log('tax ' + categorization.taxonomy)
          //console.log(' cat id ' + categorization.id)
        
          setGeneratedContent({
            categorization: categorization, 
            fullDescription: fullDescription,
            shortDescription: shortDescription, 
            metaDescription: metaDescription, 
            productName: requestTitle, 
            language: selectedLanguage,
            images: images,
            video: video
          });
          navigate('/dashboard'); 
        } 
      }
      else{
        navigate('/login');
      }  
  
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
      clearInterval(interval); // Clear the interval when done
      setProgress(0); // Set progress to 100% when done
    

    }
  }

  //const toggleMenu = () => {
  //  setIsMenuOpen(!isMenuOpen);
  //};
  
  if (location.pathname === '/dashboard') {
    return renderDashboard(generatedContent);
  }

  const openLogin = () => {
     navigate('/login')
  }


  return (
    
    <div className="App">
        <header>
          <nav>
            
            {!isMobile ? 
            <div className="logo">WTFF<span className="logo-com">.com</span>
              <div className="logo-text">Web Tools For Frontend</div>
            </div>
            :
            <div className="mobile-logo">WTFF<span className="mobile-logo-com">.com</span>
              <div className="mobile-logo-text">Web Tools For Frontend</div>
            </div>
            }


          {showLogin ?
              <button className="login-btn" onClick={() => {
                openLogin();
                setIsMenuOpen(false);
              }}>Log in</button>
              
          :
          <><div className="hello">Hello, {username ? username : null} :)</div></>
          }

          {/*
            <div className="hamburger" onClick={toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          */}

          </nav>
        </header>
        <main>
          <h1>Create e-commerce product page content in a seconds!</h1>
          <form onSubmit={submitRequest}>
            <input
              type="text"
              name="productName"
              placeholder="Type product name here. Be precise." />
            <div className="form-row">
              <select
                className="home-language"
                value={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e.target.value)}
              >
                {Array.isArray(languages) && languages.map((lang) => (
                  <option key={lang.key} value={lang.name}>
                    {lang.name}
                  </option>
                ))}
              </select>
              {isLoading ? (
                <div className="loading">Working...</div>
              ) : (
                <button type="submit">Get content</button>
              )}					
            </div>

          </form>
          <div className="progress-bar" style={{ width: `${progress}%` }}></div> {/* Progress bar */}
          <div className="alarm-note">{showAlert && 'Bad request.'}</div>

        </main>
        <footer>
          <div className="footer-menu">
              <a href={showLogin ? "/login" : "/pricing"}>Pricing</a>
              <a href={showLogin ? "/login" : "/affiliate"}>Affiliate</a>
              <a href="/privacy">Privacy</a>
              {/* <Link to={showLogin ? "/login" : "/contact"}>Contact</Link> */}
              <a href={showLogin ? "/login" : "/contact"}>Contact</a>
          </div>
          {/* <div className="copyright-sign">© 2024 All rights reserved.</div> */}
        </footer>
      </div>


  );
}

